import React from 'react';
import Cookie_Consent from "react-cookie-consent";

const CookieConsent = () => (
    <Cookie_Consent
    onDecline={() => {
      
    }}
    onAccept={() => {
      
    }}
    flipButtons
    location="bottom"
    buttonText="موافق"
    // declineButtonText="Decline"
    cookieName="elcoach-consentcookie"
    style={{ background: "#2b373bb8",direction:"rtl" }}
    contentStyle={{flex:"unset"}}
    buttonStyle={{ background: "#1fa93f",color:"white","border-radius": "5px",    width: "80px" , height:"40px" }}
    declineButtonStyle={{ background: "#dc2222",color:"white","border-radius": "5px",    width: "70px" }}
    expires={150}
  >
    <span style={{ }}>لتقديم أفضل خدمة لك، يستخدم الكوتش ملفات تعريف الارتباط (الكوكيز). الاستمرار يعني موافقتك على استلام الكوكيز من موقع الكوتش. 
    <a href="/privacy" >اضغط هنا</a> للمزيد من المعلومات.</span>
  </Cookie_Consent>
);

export default CookieConsent;